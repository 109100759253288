<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col
        cols="12"
        md="auto"
      >
        <b-img
          class="mr-2"
          src="@/assets/images/logo/mirakl_logo.png"
          width="300"
        />
        <b-img
          src="@/assets/images/misc/plus.png"
          width="100"
          height="100"
        />
        <b-img
          class="ml-2"
          src="@/assets/images/logo/linnworks_logo.png"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        col
        lg="2"
      />
      <b-col
        cols="12"
        md="auto"
      >
        <div v-if="!staged">
          <h2>
            Connect your Mirakl marketplace to Linnworks
          </h2>
          <br>
          To start receiving orders, synchronize your stock and upload new offers,
          you will need to configure your Mirakl account with us,
          <a
            class="orangeFulfillinText"
            href="https://fulfillin.com/"
          >
            Fulfillin</a>.
          <br>
          Don't worry, if you might need a help to proceed with this course, on the right bottom you can find a direct way to
          <a
            class="text-primary dashedText"
            href="https://help.fulfillin.com/chat-with-us"
          >
            contact us!
          </a>
          <br>
          <br>
          If you need to authorize to any other stage than production, you can make the choice here:
          <span
              class="text-primary dashedText"
              @click="$bvModal.show('modal-stage')"
              style="cursor: help;"
          >
            click me!
          </span>
          <br>
          In case of any unexpected issues, please provide this identification token when opening a live chat ticket:
          <br>
          <b-input-group>
            <b-form-input
              v-model="userId"
              readonly
            />
            <b-input-group-append>
              <b-button
                variant="primary"
                @click="copyAuthToken"
              >
                Copy!
              </b-button>
            </b-input-group-append>
          </b-input-group>

          <div class="mt-3">
              <div
                class="btnConnect blue"
                @click="redirectToMirakl(selectedUrl)"
              >
                <div class="logoMirakl">
                  <b-img src="@/assets/images/logo/mirakl_mini.svg" />
                </div>
                <span>Sign in with Mirakl</span>
              </div>
          </div>
        </div>
        <div
          v-if="staged"
          class="mt-2"
        >
          <div v-if="stagedLoading">
            <div class="text-center d-flex justify-content-between">
              <b-spinner
                class="maxSpinnerSize"
                label="Loading..."
              />
            </div>
          </div>
          <div v-else>
            <div v-if="stagedErrored">
              <b-alert
                :show="stagedErrored"
                variant="danger"
              >
                <h4 class="alert-heading">
                  Error has occurred!
                </h4>
                <div class="alert-body">
                  {{ stagedErrorMessage }}
                </div>
              </b-alert>
            </div>
            <div v-else>
              <h2>
                Authorization has been <span class="dashedText">successful</span>!
              </h2>
              <br>
              Your marketplace and Linnworks integration are now connected.
              <br>
              We would suggest you to follow the next steps in our documentation page to understand how to fully setup and launch your integration.
              <div
                class="underLineText mt-2"
              >
                If you've already had our integration and it is not a new one, please return to the Linnworks page tab, close the configuration
                <br>
                and re-open it again to see if all required data was saved.
              </div>
              <div class="mt-2">
                <b-button
                  variant="primary"
                  @click="closeTab"
                >
                  Close the tab!
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="justify-content-md-end">
      <b-col
        cols="12"
        md="auto"
      >
        <div class="font-small-1">
          made with
          <heart-icon
            size="1x"
            class="custom-class"
          />
          by FULFILL<span class="orangeFulfillinText">IN</span>.
        </div>
      </b-col>
    </b-row>
    <b-modal
      size="lg"
      centered
      id="modal-stage"
      title="Please select marketplace's stage"
      hide-footer
    >
      <b-container fluid>
        <b-row align-h="center">
          <b-col
            cols="14"
            align-self="stretch"
          >
            <b-button-group>
              <b-button variant="primary" @click="setAuthorizationLink(authorizeUrl)">
                Production stage
              </b-button>
              <b-button variant="primary" @click="setAuthorizationLink(authorizeUrlPre)">
                Pre-production stage
              </b-button>
              <b-button variant="primary" @click="setAuthorizationLink(authorizeUrlDev)">
                Development stage
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </b-container>
      <br>
      * If you are connecting your live marketplace account please use <span class="dashedText">ONLY production stage.</span>
      <br>
      <span style="color: red;">Please use <b>pre-production</b> and <b>development</b> stages only if you need them for testing or any other particular requirements.</span>
    </b-modal>
  </b-container>
</template>

<script>
import {
  BContainer,
  BCol,
  BRow,
  BImg,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BButton,
  BAlert,
  BSpinner,
  BModal,
  BButtonGroup,
} from 'bootstrap-vue'
import Clipboard from 'vue-clipboard2'

import { HeartIcon } from 'vue-feather-icons'
import Axios from 'axios'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BImg,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BButton,
    BAlert,
    BSpinner,
    BModal,
    BButtonGroup,
    HeartIcon,
  },
  directives: {
    Clipboard,
  },
  data() {
    return {
      userId: '0',
      staged: false,
      stagedLoading: true,
      stagedErrored: false,
      stagedErrorMessage: '',
      authorizeUrl: '',
      authorizeUrlPre: '',
      authorizeUrlDev: '',
      selectedUrl: `https://auth.mirakl.net/authorize?client_id=${process.env.VUE_APP_MIRAKL_CLIENT_ID}`,
    }
  },
  created() {
    this.authorizeUrl = `https://auth.mirakl.net/authorize?client_id=${process.env.VUE_APP_MIRAKL_CLIENT_ID}`
    this.authorizeUrlPre = `https://auth-preprod.mirakl.net/authorize?client_id=${process.env.VUE_APP_MIRAKL_CLIENT_ID_PRE}`
    this.authorizeUrlDev = `https://auth-dev.mirakl.net/authorize?client_id=${process.env.VUE_APP_MIRAKL_CLIENT_ID_DEV}`
    localStorage.setItem('selectedUrl', `https://auth.mirakl.net/authorize?client_id=${process.env.VUE_APP_MIRAKL_CLIENT_ID}`)
    const queries = this.$route.query

    if (!('code' in queries)) {
      if (!('user_id' in queries)) {
        window.location.href = process.env.VUE_APP_FULFILLIN_URL
      } else {
        this.userId = queries.user_id
        localStorage.setItem('identity', queries.user_id)
        localStorage.setItem('selectedUrl', `https://auth.mirakl.net/authorize?client_id=${process.env.VUE_APP_MIRAKL_CLIENT_ID}`)
      }
    } else {
      if ('user_id' in queries) {
        window.location.href = process.env.VUE_APP_FULFILLIN_URL
      }
      this.staged = true
      this.setAuthCode(queries.code, localStorage.getItem('identity'), localStorage.getItem('selectedUrl'))
    }
  },
  methods: {
    copyAuthToken() {
      this.$copyText(this.userId)
    },
    redirectToMirakl(stage) {
      window.location.href = stage
    },
    setAuthorizationLink(stage) {
      localStorage.setItem('selectedUrl', stage)
      this.selectedUrl = stage
      this.$bvModal.hide('modal-stage')
    },
    async setAuthCode(authCode, identity, url) {
      try {
        const response = await Axios.post(`${process.env.VUE_APP_FULFILLIN_API_URL}api/integrations/v1/mirakl/user/authorize`, { app_code: authCode, identity, url })
        this.stagedLoading = false
        if ('error' in response.data) {
          this.stagedErrored = true
          if ('error_description' in response.data.error) {
            this.stagedErrorMessage = `Error message: ${response.data.error.error_description}. Please contact support with this message and your Linnworks user email!`
          } else {
            this.stagedErrorMessage = 'The received error cannot be presented. Please retry authorization and contact support if needed.'
          }
        }
      } catch (err) {
        this.stagedLoading = false
        this.stagedErrored = true
        this.stagedErrorMessage = `${err} has occurred! Please contact support with this code: [${localStorage.getItem('identity')}] and screenshot of this page.`
      }
    },
    closeTab() {
      window.close()
    },
  },
}
</script>

<style scoped>
h2 {
  font-weight: bold;
}

.btnConnect{
  max-width: 220px;
  width:90%;
  height:48px;
  border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor:pointer;
  overflow:hidden;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;

}

.btnConnect.blue{
  background-color:#1563A3;
  color:#fff
}

.btnConnect.blue:hover{
  background-color:#0C4879;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0,0,0,0.25);
  -moz-box-shadow: 0 3px 6px 0 rgba(0,0,0,0.25);
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.25);
}

.logoMirakl{
  width:25px;
  margin-right:10px;
}

.logoMirakl svg{
  width:100%;
}

.orangeFulfillinText {
  text-decoration: none;
  color: #F89624;
}

.maxSpinnerSize {
  width: 3rem;
  height: 3rem;
}

.dashedText {
  border-bottom: 1px dashed #999;
}

.underLineText {
  text-decoration: underline;
}
</style>
